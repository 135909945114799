import { useEffect, useState } from "react";
import "./BridgeBar.scss";
import { currentEnvironment } from "../../../environments/environments";
import { Bridge_Bar_URL } from "../../../config/bridgeBarConfig";
import { LogType } from "../../../enum";
import { LogService } from "../../../services/logService";
import BridgeBarService from "../../../services/bridgeBarService";
import BridgeService from "../../../services/bridgeService";

function BridgeBar() {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (loaded) return;
    var s = document.createElement("script");
    s.src = Bridge_Bar_URL;
    document.head.appendChild(s);
    setLoaded(true);
  }, [loaded]);

  useEffect(() => {
    if (loaded) {
      const environment = currentEnvironment();
      const jti = BridgeService.getUser()?.jti;
      const message = BridgeBarService.initialize(jti, environment);
      if (message) {
        LogService.log({
          message: message,
          type: LogType.Error,
          method: "initializeBridgeBar",
          file: "BridgeBar.js",
        });
      }
    }
  }, [loaded]);

  return <span id="bridge-bar" className="bridge"></span>;
}
export default BridgeBar;
