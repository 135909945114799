import { useCallback, useEffect, useMemo, useState } from "react";
import NativeDossierContainer from "../../mstr/nativeDossier/NativeDossierContainer";
import { useNavigate } from "react-router-dom";
import "./NativeDashboardContainer.scss";
import {
  LoadedNativeDossier,
  NativeVisualsDossier,
  NativeVisualsDossierGroup,
} from "../../../model/userNativeReportsModel";
import Spinner from "../../controls/spinner/Spinner";
import NativeFilter from "../../controls/nativeFilter/NativeFilter";
import { MSTR_QUERY_PARAM } from "../../../config/mstrConfig";
import NativeDossierService from "../../../services/nativeDossierService";
import { FilterCurrentSelection } from "../../../model/dossierDefinitionModel";
import UserSettingsService from "../../../services/userSettingsService";

interface NativeDashboardContainerProps {
  nativeVisualsDossierGroups: NativeVisualsDossierGroup[];
}

interface CurrentSelection {
  currentEnterpriseSelection?: FilterCurrentSelection;
  currentCompanySelection?: FilterCurrentSelection;
}

function NativeDashboardContainer(props: NativeDashboardContainerProps) {
  const { nativeVisualsDossierGroups } = props;
  const [isLoaded, setIsLoaded] = useState(false);
  const [loadedDossiers, setLoaderDossiers] = useState<LoadedNativeDossier[]>(
    []
  );
  const navigate = useNavigate();
  const [allDossierLoaded, setAllDossierLoaded] = useState(false);
  const [currentSelection, setCurrentSelection] = useState<CurrentSelection>();

  const handleGraphicSelect = useCallback(
    (dossierId: string, pageKey: string) => {
      navigate(
        `/report?id=${dossierId}&page=${pageKey}&${MSTR_QUERY_PARAM}=true`
      );
    },
    [navigate]
  );

  const handleDossierRefreshed = useCallback(
    (loadedDossier: LoadedNativeDossier) => {
      setLoaderDossiers((prev) => {
        if (!prev.some((p) => p.id === loadedDossier.id))
          prev.push(loadedDossier);
        return [...prev];
      });
    },
    []
  );

  const nativeVisualsDossiers = useMemo(() => {
    const visualsDossiers: NativeVisualsDossier[] = [];
    nativeVisualsDossierGroups.forEach((groupVisualDossiers) =>
      groupVisualDossiers.nativePagesDossiers.forEach((pageVisualDossier) => {
        const visualsDossier: NativeVisualsDossier = {
          dossierId: pageVisualDossier.dossierId,
          visuals: [],
        };
        pageVisualDossier.nativeVisualsPages.forEach((p) =>
          visualsDossier.visuals.push(...p.visuals)
        );
        visualsDossiers.push(visualsDossier);
      })
    );
    return visualsDossiers;
  }, [nativeVisualsDossierGroups]);

  const nativeContainers = useMemo(() => {
    return nativeVisualsDossierGroups.map((nativeVisualsDossierGroup) => {
      return (
        <div
          className={"nativeGroupContainer"}
          key={nativeVisualsDossierGroup.group}
        >
          <div className={"nativeGroup"}>{nativeVisualsDossierGroup.group}</div>
          <div
            className={"nativeVisualsContainer"}
            key={`${nativeVisualsDossierGroup.group}`}
          >
            {nativeVisualsDossierGroup.nativePagesDossiers.map(
              (nativePagesDossier) => {
                return nativePagesDossier.nativeVisualsPages.map(
                  (nativeVisualPage) => {
                    return nativeVisualPage.visuals.map((visual) => {
                      return (
                        <div
                          id={`${nativePagesDossier.dossierId}-${visual}`}
                          data-testid={`${nativePagesDossier.dossierId}-${visual}`}
                          className={`nativeVisual ${
                            loadedDossiers.some(
                              (ld) => ld.id === nativePagesDossier.dossierId
                            )
                              ? ""
                              : "border"
                          }`}
                          key={`visual-${nativePagesDossier.dossierId}-${visual}`}
                          onClick={() =>
                            handleGraphicSelect(
                              nativePagesDossier.dossierId,
                              nativeVisualPage.pageKey
                            )
                          }
                          //@ts-ignore
                          sandbox="allow-same-origin allow-scripts"
                        ></div>
                      );
                    });
                  }
                );
              }
            )}
          </div>
        </div>
      );
    });
  }, [handleGraphicSelect, loadedDossiers, nativeVisualsDossierGroups]);

  useEffect(() => setIsLoaded(true), []);
  useEffect(() => {
    if (loadedDossiers.length === nativeVisualsDossiers.length)
      setAllDossierLoaded(true);
  }, [loadedDossiers.length, nativeVisualsDossiers.length]);

  useEffect(() => {
    initializeFilters();

    async function initializeFilters() {
      const filterPreference =
        NativeDossierService.getLastAppliedGlobalFilters();
      const filters =
        NativeDossierService.getFilterModelFromFilterPreference(
          filterPreference
        );
      const currentSelection = NativeDossierService.getCurrentSelection({
        ...filters,
        enterpriseUpdated: true,
        companyUpdated: true,
      });
      setCurrentSelection(currentSelection);
    }
  }, []);

  useEffect(() => {
    if (allDossierLoaded) {
      const preference = UserSettingsService.preference();
      if (!preference) return;
      preference.reports = [];
      UserSettingsService.setUserPreference(preference);
    }
  }, [allDossierLoaded]);

  return (
    <>
      {!allDossierLoaded && (
        <div className="spinner" data-testid="spinner">
          <Spinner size={75}></Spinner>
        </div>
      )}
      {allDossierLoaded && loadedDossiers.length > 0 && (
        <NativeFilter loadedDossiers={loadedDossiers}></NativeFilter>
      )}
      {nativeContainers}
      {isLoaded && currentSelection && (
        <NativeDossierContainer
          nativeVisualsDossiers={nativeVisualsDossiers}
          onDossierRefreshed={handleDossierRefreshed}
          enterprises={currentSelection.currentEnterpriseSelection}
          companies={currentSelection.currentCompanySelection}
        ></NativeDossierContainer>
      )}
    </>
  );
}

export default NativeDashboardContainer;
