import { BridgeUser } from "../model/bridgeUserModel";
import {
  AttributeElement,
  DossierFirstPage,
  DossierGlobalFilterKey,
} from "../model/dossierDefinitionModel";
import { PendoMetadata } from "../model/pendoModel";
import { UserAccount } from "../model/userAccountModel";
import { NativePagesDossier } from "../model/userNativeReportsModel";
import { UserPreference } from "../model/userPreferenceModel";
import { UserReport } from "../model/userReportsModel";

const PENDO_MODEL = "pendo_model";
const USER_REPORTS = "user_reports";
const USER_ACCOUNT = "user_account";
const USER_PREFERENCE = "user_preference";
const USER_MSTR_ID = "user_mstr_id";
const BRIDGE_USER = "bridge_user";
const USER_NATIVE_REPORTS = "user_native_report";
const DOSSIER_GLOBAL_FILTER_KEYS = "native_report_global_filter_keys";
const REPORT_FIRST_PAGE = "report_first_page";

export const PendoSessionService = {
  setPendoMetadata: (pendoModel: PendoMetadata) =>
    sessionStorage.setItem(PENDO_MODEL, JSON.stringify(pendoModel)),
  getPendoMetadata: (): PendoMetadata | undefined => {
    const pendoModel = sessionStorage.getItem(PENDO_MODEL);
    if (pendoModel) {
      return JSON.parse(pendoModel) as PendoMetadata;
    }
    return undefined;
  },
  clearPendoMetadata: () => sessionStorage.removeItem(PENDO_MODEL),
};

export const UserSettingsSessionService = {
  setUserReports: (userReports: UserReport[]) =>
    sessionStorage.setItem(USER_REPORTS, JSON.stringify(userReports)),
  getUserReports: (): UserReport[] | undefined => {
    try {
      const userReportsFromSessionStorage =
        sessionStorage.getItem(USER_REPORTS);
      if (userReportsFromSessionStorage) {
        return JSON.parse(userReportsFromSessionStorage) as UserReport[];
      }
    } catch (e) {
      console.log(e);
    }

    return undefined;
  },
  clearUserReports: () => sessionStorage.removeItem(USER_REPORTS),

  setUserAccount: (userAccount: UserAccount) =>
    sessionStorage.setItem(USER_ACCOUNT, JSON.stringify(userAccount)),
  getUserAccount: (): UserAccount | undefined => {
    const userAccountsFromSessionStorage = sessionStorage.getItem(USER_ACCOUNT);
    if (userAccountsFromSessionStorage) {
      return JSON.parse(userAccountsFromSessionStorage) as UserAccount;
    }
    return undefined;
  },
  clearUserAccount: () => sessionStorage.removeItem(USER_ACCOUNT),

  setUserPreference: (userPreference: UserPreference) =>
    sessionStorage.setItem(USER_PREFERENCE, JSON.stringify(userPreference)),
  getUserPreference: (): UserPreference | undefined => {
    try {
      const userPreference = sessionStorage.getItem(USER_PREFERENCE);
      if (userPreference) {
        return JSON.parse(userPreference) as UserPreference;
      }
    } catch (e) {
      console.log(e);
    }
    return undefined;
  },
  clearUserPreference: () => sessionStorage.removeItem(USER_PREFERENCE),
};

export const MstrSessionService = {
  setUserMstrId: (id: string) => sessionStorage.setItem(USER_MSTR_ID, id),
  getUserMstrId: () => sessionStorage.getItem(USER_MSTR_ID),
  clearUserMstrId: () => sessionStorage.removeItem(USER_MSTR_ID),

  setAttributeElements: (
    attributeElements: AttributeElement[],
    attributeId: string
  ) => sessionStorage.setItem(attributeId, JSON.stringify(attributeElements)),
  getAttributeElements: (attributeId: string) => {
    try {
      const attributeElements = sessionStorage.getItem(attributeId);
      if (attributeElements) {
        return JSON.parse(attributeElements) as AttributeElement[];
      }
    } catch (e) {
      console.log(e);
    }
    return undefined;
  },
  clearAttributeElements: (attributeId: string) =>
    sessionStorage.removeItem(attributeId),
};

export const BridgeSessionService = {
  setBridgeUser: (model: BridgeUser) =>
    sessionStorage.setItem(BRIDGE_USER, JSON.stringify(model)),
  getBridgeUser: () => {
    const model = sessionStorage.getItem(BRIDGE_USER);
    if (model) {
      return JSON.parse(model) as BridgeUser;
    }
    return undefined;
  },
  clearBridgeUser: () => sessionStorage.removeItem(BRIDGE_USER),
};

export const NativeDashboardSessionService = {
  setUserNativeReports: (userNativeReports: NativePagesDossier[]) =>
    sessionStorage.setItem(
      USER_NATIVE_REPORTS,
      JSON.stringify(userNativeReports)
    ),
  getUserNativeReports: (): NativePagesDossier[] => {
    try {
      const userReportsFromSessionStorage =
        sessionStorage.getItem(USER_NATIVE_REPORTS);
      if (userReportsFromSessionStorage) {
        return JSON.parse(
          userReportsFromSessionStorage
        ) as NativePagesDossier[];
      }
    } catch (e) {
      console.log(e);
    }
    return [];
  },
  clearUserNativeReports: () => sessionStorage.removeItem(USER_NATIVE_REPORTS),

  setNativeDossierGlobalFilterKeys: (
    dossierGlobalFilterKeys: DossierGlobalFilterKey[]
  ) =>
    sessionStorage.setItem(
      DOSSIER_GLOBAL_FILTER_KEYS,
      JSON.stringify(dossierGlobalFilterKeys)
    ),
  getNativeDossierGlobalFilterKeys: (): DossierGlobalFilterKey[] => {
    try {
      const dossierGlobalFilterKeys = sessionStorage.getItem(
        DOSSIER_GLOBAL_FILTER_KEYS
      );
      if (dossierGlobalFilterKeys) {
        return JSON.parse(dossierGlobalFilterKeys) as DossierGlobalFilterKey[];
      }
    } catch (e) {
      console.log(e);
    }
    return [];
  },
  clearNativeDossierGlobalFilterKeys: () =>
    sessionStorage.removeItem(DOSSIER_GLOBAL_FILTER_KEYS),

  setReportsFirstPage: (dossierFirstPage: DossierFirstPage[]) =>
    sessionStorage.setItem(REPORT_FIRST_PAGE, JSON.stringify(dossierFirstPage)),
  getReportsFirstPage: (): DossierFirstPage[] => {
    try {
      const reportFirstPages = sessionStorage.getItem(REPORT_FIRST_PAGE);
      if (reportFirstPages) {
        return JSON.parse(reportFirstPages) as DossierFirstPage[];
      }
    } catch (e) {
      console.log(e);
    }
    return [];
  },
  clearReportsFirstPage: () => sessionStorage.removeItem(REPORT_FIRST_PAGE),
};
