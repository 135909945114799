import { BridgeUser } from "../model/bridgeUserModel";
import { BridgeSessionService } from "../utility/sessionStorageHelper";

export interface InitializeBridgeResponse {
  bridgeUserInitialized?: boolean;
}

function setUser(user: BridgeUser) {
  BridgeSessionService.setBridgeUser(user);
}
function getUser() {
  return BridgeSessionService.getBridgeUser();
}

function sub() {
  return BridgeSessionService.getBridgeUser()?.sub ?? "";
}

function initialize(): InitializeBridgeResponse {
  let response = false;
  const bridgeUser = BridgeSessionService.getBridgeUser();
  if (bridgeUser) response = true;
  return { bridgeUserInitialized: response };
}

function clear() {
  BridgeSessionService.clearBridgeUser();
}
const BridgeService = { initialize, setUser, getUser, sub, clear };
export default BridgeService;
