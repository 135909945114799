import { LogType } from "../enum";
import { currentEnvironment } from "../environments/environments";
import { UserAccount } from "../model/userAccountModel";
import { UserPreference } from "../model/userPreferenceModel";
import { UserReport } from "../model/userReportsModel";
import {
  UserSettingsSessionService,
  BridgeSessionService,
} from "../utility/sessionStorageHelper";
import { UserSettingsApiService } from "./apiService";
import { LogService } from "./logService";

export interface UserSettingsInitializeResponse {
  reportsInitialized: boolean;
  accountInitialized: boolean;
  preferenceInitialized: boolean;
}

const userSettingUrl = currentEnvironment().customConfiguration.userSettingUrl;

const commonOrgIds = () =>
  UserSettingsSessionService.getUserAccount()
    ?.sources?.map((s) => s.companyId ?? "")
    .join(",") ?? "";

const reportById = (reportId: string) =>
  UserSettingsSessionService.getUserReports()?.find(
    (f) => f.reportId === reportId
  );

const reports = () => UserSettingsSessionService.getUserReports() ?? [];
const account = () => UserSettingsSessionService.getUserAccount();
const preference = () => UserSettingsSessionService.getUserPreference();

function init() {
  UserSettingsApiService.get(`${userSettingUrl}/user/reports/init`);
  UserSettingsApiService.get(`${userSettingUrl}/user/account/init`);
  UserSettingsApiService.get(`${userSettingUrl}/user/preference/init`);
}

async function setUserPreference(userPreference: UserPreference) {
  try {
    const model = BridgeSessionService.getBridgeUser();
    if (!model?.accessToken) {
      throw Error("access token now present");
    }
    userPreference.sub = model.sub;
    UserSettingsSessionService.setUserPreference(userPreference);

    const response = await UserSettingsApiService.post(
      `${userSettingUrl}/user/preference`,
      userPreference,
      model.accessToken
    );
    if (!response?.ok) {
      throw Error(`Unable to save user preference for user ${model.sub}`);
    }
  } catch (e) {
    LogService.log({
      message: `${e}`,
      type: LogType.Error,
      method: "setLastAppliedGlobalFilters",
      file: "NativeDossierService.ts",
    });
  }
}

async function initReportsFromApi(token: string): Promise<boolean> {
  try {
    const path = `${userSettingUrl}/user/reports`;
    const response = await UserSettingsApiService.get(path, token);

    if (!response?.ok) {
      LogService.log({
        message: `api call to setting api has failed`,
        type: LogType.Error,
        method: "getUserReports",
        file: "userSettingsService.ts",
      });
      return false;
    }

    const userReports = (await response.json()) as UserReport[];
    UserSettingsSessionService.setUserReports(userReports);

    return true;
  } catch (e) {
    LogService.log({
      message: `${e}`,
      type: LogType.Error,
      method: "getUserReports",
      file: "userSettingsService.ts",
    });
  }
  return false;
}

async function initReports(token: string): Promise<boolean> {
  const reports = UserSettingsSessionService.getUserReports();
  if (!reports) {
    const response = await initReportsFromApi(token);
    return response;
  } else {
    return true;
  }
}

async function initAccountFromApi(token: string): Promise<boolean> {
  try {
    const path = `${userSettingUrl}/user/account`;
    const response = await UserSettingsApiService.get(path, token);

    if (!response?.ok) {
      LogService.log({
        message: `api call to setting api to get user account has failed`,
        type: LogType.Error,
        method: "getUserAccount",
        file: "userSettingsService.ts",
      });
      return false;
    }

    const userAccount = (await response.json()) as UserAccount;
    UserSettingsSessionService.setUserAccount(userAccount);

    return true;
  } catch (e) {
    LogService.log({
      message: `${e}`,
      type: LogType.Error,
      method: "getUserAccount",
      file: "userSettingsService.ts",
    });
  }
  return false;
}

async function initAccount(token: string): Promise<boolean> {
  const account = UserSettingsSessionService.getUserAccount();
  if (!account) {
    const response = await initAccountFromApi(token);
    return response;
  } else {
    return true;
  }
}

async function initPreferenceFromApi(token: string) {
  try {
    const path = `${userSettingUrl}/user/preference`;
    const response = await UserSettingsApiService.get(path, token);
    if (!response?.ok) {
      LogService.log({
        message: `api call to setting api to get user preference has failed`,
        type: LogType.Error,
        method: "getUserPreference",
        file: "userSettingsService.ts",
      });
      return false;
    }
    const userPreference = (await response.json()) as UserPreference;

    UserSettingsSessionService.setUserPreference(userPreference);
    return true;
  } catch (e) {
    LogService.log({
      message: `${e}`,
      type: LogType.Error,
      method: "getUserPreference",
      file: "userSettingsService.ts",
    });
  }
  return false;
}

async function initPreference(token: string): Promise<boolean> {
  const userPreference = UserSettingsSessionService.getUserPreference();
  if (!userPreference) {
    const response = initPreferenceFromApi(token);
    return response;
  } else {
    return true;
  }
}

async function initialize(): Promise<UserSettingsInitializeResponse> {
  const model = BridgeSessionService.getBridgeUser();
  if (!model?.accessToken) {
    return {
      reportsInitialized: false,
      accountInitialized: false,
      preferenceInitialized: false,
    };
  }
  const [reportsInitialized, accountInitialized, preferenceInitialized] =
    await Promise.all([
      initReports(model.accessToken),
      initAccount(model.accessToken),
      initPreference(model.accessToken),
    ]);
  return {
    reportsInitialized,
    accountInitialized,
    preferenceInitialized,
  };
}

async function updateFilterPreferenceReport(id: string) {
  const preference = UserSettingsSessionService.getUserPreference();
  if (preference && !preference.reports.includes(id)) {
    preference.reports.push(id);
    await setUserPreference(preference);
  }
}

function clear() {
  UserSettingsSessionService.clearUserReports();
  UserSettingsSessionService.clearUserAccount();
  UserSettingsSessionService.clearUserPreference();
}

const UserSettingsService = {
  initialize,
  clear,
  init,
  commonOrgIds,
  reportById,
  reports,
  account,
  setUserPreference,
  preference,
  updateFilterPreferenceReport,
};

export default UserSettingsService;
