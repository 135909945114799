import React, { useState, useEffect, useCallback } from "react";
import { NavigateOptions, useNavigate } from "react-router-dom";
import { useFlags } from "launchdarkly-react-client-sdk";
import { ErrorMessages } from "../../../utility/Messages";
import PendoService from "../../../services/pendoService";
import { pendoFeatureFlag } from "../../../utility/launchDarklyFlagHelper";
import DossierService, {
  OneViewDossierConfig,
} from "../../../services/dossierService";
import { DossierEventType, LogType, VisualSize } from "../../../enum";
import getError, { ErrorModel } from "../../../utility/errorHelper";
import { LogService } from "../../../services/logService";
import { DossierFilterModel } from "../../../model/dossierFilterModel";

const Dossier: React.FC<any> = ({
  containerId,
  dossierId,
  authToken,
  style,
  pageKey,
  visualId,
  size,
  resizeButtonVisible,
  onGraphicSelected,
  mstrLink,
  isBookmark,
}) => {
  const [error, setError] = useState<ErrorModel | undefined>(undefined);
  const isVisualMaximizedDashboard = size === VisualSize.Maximized;
  const navigate = useNavigate();
  const launchDarklyFlags = useFlags();

  const handleFilterUpdated = useCallback(
    (e: { filterInfo: DossierFilterModel[] }) => {
      DossierService.handleLocalFilterUpdated(e.filterInfo, dossierId);
    },
    [dossierId]
  );

  const errorHandler = useCallback((error: any) => {
    LogService.log({
      message: `Error in microStrategy report loading : ${error.message} - ${error.desc}`,
      type: LogType.Error,
      method: "errorHandler",
      file: "Dossier.tsx",
    });
    setError(getError(ErrorMessages.mstrReportLoadError));
  }, []);

  const sessionErrorHandler = useCallback((error: any) => {
    LogService.log({
      message: `MicroStrategy Session error : ${error.message} - ${error.desc}`,
      type: LogType.Error,
      method: "sessionErrorHandler",
      file: "Dossier.tsx",
    });
    setError(getError(ErrorMessages.sessionError));
  }, []);

  useEffect(() => setError(undefined), []);

  useEffect(() => {
    if (!error) {
      return;
    }

    const path = "/error";
    const options: NavigateOptions = {
      state: { error },
    };

    navigate(path, options);
  }, [error, navigate]);

  useEffect(() => {
    const placeholder = document.getElementById(containerId);
    if (!placeholder) {
      return;
    }

    initializeDossier(placeholder);
    return cleanup;

    async function initializeDossier(placeholder: HTMLElement) {
      try {
        const config: OneViewDossierConfig = {
          authToken,
          dossierId,
          errorHandler,
          sessionErrorHandler,
          pageKey,
          visualId,
          placeholder,
          isNormalSize: !isVisualMaximizedDashboard,
          isBookmark,
          mstrLink,
        };

        const dossierProps = DossierService.getDossierProperties(config);
        const instance = await DossierService.createDossier(dossierProps);
        DossierService.setVisualizationNormalSizeForReport(instance, config);
        DossierService.addDossierErrorHandler(instance, errorHandler);
        DossierService.registerEvent(
          instance,
          handleFilterUpdated,
          DossierEventType.FilterUpdated
        );
        if (onGraphicSelected)
          DossierService.registerEvent(
            instance,
            onGraphicSelected,
            DossierEventType.GraphicSelected
          );
        performPostInstanceLoadOperationsIfRequired();
      } catch (error) {
        LogService.log({
          message: `Encounter error while initializing the dossier : ${error}`,
          type: LogType.Error,
          method: "initializeDossier",
          file: "Dossier.tsx",
        });
      }

      function performPostInstanceLoadOperationsIfRequired() {
        if (launchDarklyFlags[pendoFeatureFlag]) {
          PendoService.postMessage(containerId);
        }
      }
    }

    function cleanup() {
      DossierService.destroyDossier(placeholder);
    }
  }, [
    dossierId,
    authToken,
    containerId,
    visualId,
    isVisualMaximizedDashboard,
    resizeButtonVisible,
    onGraphicSelected,
    pageKey,
    errorHandler,
    sessionErrorHandler,
    isBookmark,
    launchDarklyFlags,
    mstrLink,
    handleFilterUpdated,
  ]);

  if (error) return <></>;

  return (
    <>
      <div
        id={containerId}
        style={{
          display: authToken ? "flex" : "none",
          flexGrow: 1,
          height: "100%",
          ...style,
        }}
        //@ts-ignore
        sandbox="allow-same-origin allow-scripts"
      />
    </>
  );
};

export default Dossier;
