import { LogType, MstrMessageType } from "../enum";
import { currentEnvironment } from "../environments/environments";
import { BridgeUser } from "../model/bridgeUserModel";
import { MstrMessage, PendoMstrMessage } from "../model/mstrMessageModel";
import { PendoAccount, PendoMetadata, PendoVisitor } from "../model/pendoModel";
import { UserAccount } from "../model/userAccountModel";
import { PendoSessionService } from "../utility/sessionStorageHelper";
import { getFirstAndLastName } from "../utility/utils";
import { LogService } from "./logService";
import MstrMessagingService from "./mstrMessagingService";

function save(pendoModel: PendoMetadata) {
  PendoSessionService.setPendoMetadata(pendoModel);
}

function get(): PendoMetadata | undefined {
  return PendoSessionService.getPendoMetadata();
}

function clear() {
  PendoSessionService.clearPendoMetadata();
}

const postMessage = (id: string) => {
  try {
    const pendoModel = get();
    if (!pendoModel) return;
    const mstrMessage: MstrMessage = {
      type: MstrMessageType.Pendo,
      message: pendoModel as PendoMstrMessage,
    };
    if (pendoModel) MstrMessagingService.postToDossier(id, mstrMessage);
  } catch (error) {
    LogService.log({
      message: `Error while sending pendo initialization message to the dossier : ${error}`,
      type: LogType.Error,
      method: "postPendoMessageToDossier",
      file: "pendoService.ts",
    });
  }
};

const initialize = (user: BridgeUser, account: UserAccount) => {
  try {
    let metadata: PendoMetadata = {
      visitor: mapToVisitor(user),
      account: mapToAccount(account),
    };

    save(metadata);

    const pendo = (window as any).pendo;
    pendo?.initialize(metadata);
  } catch (error) {
    LogService.log({
      message: `Error while initializing the pendo ${JSON.stringify(error)}`,
      type: LogType.Error,
      method: "initialize",
      file: "PendoService.ts",
    });
  }
};

const mapToVisitor = (user: BridgeUser): PendoVisitor => {
  const { firstName, lastName } = getFirstAndLastName(user.name);
  const environment = currentEnvironment().customConfiguration.product;
  const visitor: PendoVisitor = {
    id: user.sub,
    first_name: firstName,
    last_name: lastName,
    bridge_user_name: user.userName,
    product: environment,
    rad_environment: environment,
  };

  return visitor;
};

const mapToAccount = (userAccount: UserAccount): PendoAccount => {
  let id: string = "";
  let name: string = "";
  let companies: string[] = [];
  let personas: string[] = [];
  let sources: string[] = [];
  let sourcePersona: string[] = [];

  userAccount.sources.forEach((source) => {
    id = source.enterpriseId;
    name = source.enterpriseName;
    companies.push(source.companyName);
    personas.push(source.persona ?? "-");
    sources.push(source.name);
    sourcePersona.push([source.name, source.persona ?? ""].join("_"));
  });

  const separator: string = ", ";
  const account: PendoAccount = {
    id,
    name,
    rad_company_name: companies.join(separator),
    rad_persona: personas.join(separator),
    rad_sources: sources.join(separator),
    rad_source_with_persona: sourcePersona.join(separator),
  };
  return account;
};

const PendoService = {
  save,
  get,
  clear,
  postMessage,
  initialize,
};

export default PendoService;
