import React, { useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { toRelativeUrl } from "@okta/okta-auth-js";
import { Outlet } from "react-router-dom";
import Loader from "../controls/loader/Loader";
import { LoaderMessages } from "../../utility/Messages";
import BridgeService from "../../services/bridgeService";

export const SecureRoute: React.FC = () => {
  const { oktaAuth, authState } = useOktaAuth();
  useEffect(() => {
    if (!authState) {
      return;
    }

    if (!authState.isAuthenticated) {
      const originalUri = toRelativeUrl(
        window.location.href,
        window.location.origin
      );
      oktaAuth.setOriginalUri(originalUri);
      oktaAuth.signInWithRedirect();
    } else if (authState.accessToken && authState.idToken) {
      BridgeService.setUser({
        userName: authState.idToken.claims.preferred_username,
        accessToken: authState.accessToken.accessToken,
        sub: authState.accessToken.claims.sub,
        name: authState.idToken.claims.name,
        jti: authState.accessToken.claims.jti,
      });
    }
  }, [oktaAuth, authState?.isAuthenticated, authState]);

  if (!authState || !authState?.isAuthenticated) {
    return <Loader message={LoaderMessages.signIn} />;
  }
  return <Outlet />;
};
