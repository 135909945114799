import { LDFlagSet } from "launchdarkly-react-client-sdk";
import {
  NativePagesDossier,
  NativeVisualsDossierGroup,
  NativeVisualsPage,
} from "../model/userNativeReportsModel";
import { NativeDashboardSessionService } from "../utility/sessionStorageHelper";
import MstrRestService from "./mstrRestService";
import UserSettingsService from "./userSettingsService";
import { getLaunchDarklyFlagKeyFromReportName } from "../utility/launchDarklyFlagHelper";
import { UserReport } from "../model/userReportsModel";
import { DossierGlobalFilterKey } from "../model/dossierDefinitionModel";

const keys = () =>
  NativeDashboardSessionService.getNativeDossierGlobalFilterKeys();
const page = (id: string) => {
  const reportsPage = NativeDashboardSessionService.getReportsFirstPage();
  return reportsPage.find((r) => r.dossierId === id)?.firstPageKey;
};

async function getDefinition(
  report: UserReport,
  nativeReports: NativePagesDossier[],
  nativeDossierGlobalFilterKeys: DossierGlobalFilterKey[]
) {
  const dossierDefinition = await MstrRestService.dossierDefinitions(
    report.reportId
  );
  const dossierKpiVisualization: NativeVisualsPage[] = [];
  const dossierEnterpriseKeys: string[] = [];
  const dossierCompanyKeys: string[] = [];
  dossierDefinition?.chapters.forEach((chapter) => {
    chapter.pages.forEach((page, index) => {
      if (index === 0) SetReportFirstPage(report.reportId, page.key);
      const pageKpiVisualization = page.visualizations
        .filter((v) => v.name.toLowerCase().includes("kpi"))
        .map((v) => v.key);
      if (pageKpiVisualization.length > 0) {
        dossierKpiVisualization.push({
          pageKey: page.key,
          visuals: pageKpiVisualization,
        });
      }
    });
    const enterpriseKey = chapter.filters.find((s) =>
      s.name.toLowerCase().includes("enterprise")
    )?.key;
    if (enterpriseKey) dossierEnterpriseKeys.push(enterpriseKey);
    const companyKey = chapter.filters.find((s) =>
      s.name.toLowerCase().includes("company")
    )?.key;
    if (companyKey) dossierCompanyKeys.push(companyKey);
  });
  if (dossierKpiVisualization.length > 0) {
    nativeReports.push({
      dossierId: report.reportId,
      nativeVisualsPages: dossierKpiVisualization,
    });
  }
  nativeDossierGlobalFilterKeys.push({
    dossierId: report.reportId,
    enterpriseKeys: dossierEnterpriseKeys,
    companyKeys: dossierCompanyKeys,
  });
}

function SetReportFirstPage(reportId: string, pageKey: string) {
  const reportsFirstPage = NativeDashboardSessionService.getReportsFirstPage();
  if (!reportsFirstPage.some((r) => r.dossierId === reportId)) {
    reportsFirstPage.push({
      dossierId: reportId,
      firstPageKey: pageKey,
    });
    NativeDashboardSessionService.setReportsFirstPage(reportsFirstPage);
  }
}

async function reports(flags: LDFlagSet): Promise<NativeVisualsDossierGroup[]> {
  let nativeReports = NativeDashboardSessionService.getUserNativeReports();
  const userReports = UserSettingsService.reports();
  let nativeDossierGlobalFilterKeys =
    NativeDashboardSessionService.getNativeDossierGlobalFilterKeys();
  const definitions: Promise<void>[] = [];
  if (
    nativeReports.length === 0 ||
    nativeDossierGlobalFilterKeys.length === 0
  ) {
    for (let i = 0; i < userReports.length; i++) {
      const report = userReports[i];
      definitions.push(
        getDefinition(report, nativeReports, nativeDossierGlobalFilterKeys)
      );
    }
    await Promise.all(definitions);
    NativeDashboardSessionService.setUserNativeReports(nativeReports);
    NativeDashboardSessionService.setNativeDossierGlobalFilterKeys(
      nativeDossierGlobalFilterKeys
    );
  }
  const nativeVisualsDossierGroup: NativeVisualsDossierGroup[] = [];
  nativeReports.forEach((nv) => {
    const report = userReports.find((r) => r.reportId === nv.dossierId);
    if (report) {
      const group = report.reportGroupName;
      const hasAccess =
        flags[getLaunchDarklyFlagKeyFromReportName(report.reportName)];
      if (group && hasAccess) {
        const nativeGroup = nativeVisualsDossierGroup.find(
          (ng) => ng.group === group
        );
        if (nativeGroup) nativeGroup.nativePagesDossiers.push(nv);
        else
          nativeVisualsDossierGroup.push({
            group: group,
            nativePagesDossiers: [nv],
          });
      }
    }
  });
  return nativeVisualsDossierGroup;
}

const clear = () => {
  NativeDashboardSessionService.clearNativeDossierGlobalFilterKeys();
  NativeDashboardSessionService.clearReportsFirstPage();
  NativeDashboardSessionService.clearUserNativeReports();
};

const NativeDashboardService = { reports, keys, page, clear };
export default NativeDashboardService;
